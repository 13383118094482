* {
    font-family: "Open sans",serif !important;
}
.sol-hide-print{
	display: none !important;
}
.sol-show-print{
	display: block !important;
}

.sol-article-item .sol-article-item-img,
.sol-news-menu,
.sol-publish-info .sol-print,
.font-btn,
.sol-startpage-wrapper{
	display: none;
}
.sol-article-item{
	padding-top: 20px;
	 padding-left: 60px;
}
.sol-article-item .sol-article-item-date{
	color: black;
	background: none;
	border-radius: 0;
	height: 40px;
	width: 40px;
	left: 0;
	top: 0;
	padding: 0;
}

.sol-publish-info{
	border: none;
	border-top: 1px solid #e5e5e5;
  border-radius: 0;
	margin-top: 0;
}
.sol-article-wrapper .sv-image-portlet .sol-article-date{
	color: black;
	background-color: white;
}
.pagecontent{
	padding: 20px;
}
table *{
	border-color: black !important;
	border-radius: 0 !important;
}
table th p{
	color: black !important;
}
table{
	border: 1px solid black;
}
table tr{
	border-bottom: 1px solid black;
}
header{
	display: none;
}
.sol-collapsible{
	break-inside: avoid;
}
.sol-content-container{
	padding: 0 !important;
}